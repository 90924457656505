import hslogo from '../assets/hslogo.png';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import { Button } from 'antd';
import { HomeOutlined } from '@ant-design/icons';

function SuccessPage() {
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            // Auto redirect after 5 seconds
            navigate('/');
        }, 5000);

        return () => {
            clearTimeout(timer); // Clear the timer if the component unmounts or the button is clicked
        };
    }, [navigate]);

    const handleReturnButtonClick = () => {
        navigate("/");
    };

    return (
        <div className="success-page w-10/12 h-5/6 flex flex-col justify-center items-center">
            <Button className="home-button" type="primary" shape="circle" icon={<HomeOutlined />} size="large" onClick={handleReturnButtonClick} />
            <img src={hslogo} className="mx-auto"></img>
            <div className='flex'>&nbsp;</div>
            <div className='success-page-title text-4xl font-bold hengseng-gray-500'>Welcome to Joined Us !</div>
        </div>
    );

}

export default SuccessPage;